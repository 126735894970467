<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="24">
        <div class="form-group">
          <el-form-item >
            <label  class="field-title-label">Heading / Title <span class="red-asterisk">*</span></label>
            <el-input type="text" v-model="field.label" placeholder="Enter content or block title"></el-input>
          </el-form-item>
        </div>
      </el-col>

      <el-col :span="24">
        <div class="form-group">
          <el-form-item >
            <label  class="field-title-label">Heading Type<span class="red-asterisk">*</span></label>
            <br/>
          <el-select v-model="field.heading_type" value-key="field.heading_type" placeholder="Select Heading Type">
            <el-option
             v-for="item in options"
             :key="item" 
             :label="item"
              :value="item"
              ></el-option>
          </el-select>
          </el-form-item>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name:"templates-formComponents-Content",
  props: ["field"],
  data() {
    return {
      options: ["H1", "H2", "H3", "H4", "H5", "H6"],
      heading_type: ""
    };
  },
  mounted() {
  }
};
</script>

<style lang="scss">
.mgtop{
margin-top:10px;
}
.red-asterisk {
  color: red;
}
</style>